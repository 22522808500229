import { AddressData } from '@/models/auth/address.class';

export class Register {
  firstName = '';
  lastName = '';
  email = '';
  confirmEmail = '';
  password = '';
  confirmPassword = '';
  phone = '';
  addressData = new AddressData();

  agreeWithTerms = false;
  language?: string = '';
  allowMarketing = false;
}
