
import { Component, Vue, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Login as LoginModel } from '@/models/auth/login.class';
import { UseCurrentLocaleMixin } from '../mixins/use-current-locale.mixin';

const authModule = namespace('auth');

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class Login extends Mixins(UseCurrentLocaleMixin) {
  user: LoginModel = new LoginModel();

  @authModule.Action('login')
  login!: (login: LoginModel) => Promise<boolean | string>;

  errorTranslationKey = '';
  showPassword = false;
  showLoading = false;

  $refs!: Vue['$refs'] & {
    observer: InstanceType<typeof ValidationObserver>;
  };

  async onSubmit() {
    this.showLoading = true;
    this.errorTranslationKey = '';

    const loginResponse = await this.login(this.user);
    this.showLoading = false;

    if (typeof loginResponse === 'string') {
      this.errorTranslationKey = loginResponse;
      this.resetForm();
    } else {
      const redirectPath = this.$route.query.redirect as string;
      if (redirectPath) {
        this.$router.push(redirectPath);
      } else {
        this.$router.push(`${this.localeBaseUrlPath}/`);
      }
    }
  }

  resetForm() {
    this.user = new LoginModel();
    this.$refs.observer.reset();
  }
}
