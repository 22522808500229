
import { Component, Mixins } from 'vue-property-decorator';
import Login from '../components-smart/Login.vue';
import Register from '../components-smart/Register.vue';
import { UsePageHeaderMixin } from '../mixins/use-page-header.mixin';

@Component({
  components: {
    Login,
    Register,
  },
})
export default class LoginRegister extends Mixins(UsePageHeaderMixin) {
  get headerHtml() {
    return `<h2 class="text-white">${this.$t('login.title')}</h2>`;
  }
}
